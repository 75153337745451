import React from "react";

function comparison_table() {
  return (
    <div style={{ marginLeft: "auto", marginRight: "auto", width: 1294 }}>
      <table border="2" solid="" black="" cellpadding="0" cellspacing="0">
        <colgroup>
          <col width="217" />
          <col width="245" />
          <col width="199" />
          <col width="191" />
          <col width="216" />
          <col width="212" />
        </colgroup>
        <tbody>
          <tr>
            {/* <!--<td height="42">&nbsp;</td>--> */}
            <td rowspan="2" colspan="2">
              &nbsp;
            </td>
            <td height="42">
              <div align="center">
                <strong>Mobile</strong>
              </div>
            </td>
            <td height="42">
              <div align="center">
                <strong>Semi-stable</strong>
              </div>
            </td>
            <td height="42">
              <div align="center">
                <strong>Traditional stable</strong>
              </div>
            </td>
            <td height="42">
              <div align="center">
                <strong>Pi Foam System</strong>
              </div>
            </td>
          </tr>
          <tr>
            {/* <!--<td height="72">&nbsp;</td>
  <td width="245" height="72">&nbsp;</td>--> */}
            <td width="199" height="72">
              <div align="center">
                <strong>
                  Normal fire-vehicle system with water tank and foam monitor
                </strong>
              </div>
            </td>
            <td width="191" height="72">
              <div align="center">
                <strong>
                  Semi-stable firefighting system with water/foam source
                </strong>
              </div>
            </td>
            <td width="216" height="72">
              <div align="center">
                <strong>
                  Automatic pump and foam generator-type extinguishment system
                </strong>
              </div>
            </td>
            <td width="212" height="72">
              <div align="center">
                <strong>
                  Tank-type pneumatic detector activated Pi Foam System
                </strong>
              </div>
            </td>
          </tr>
          <tr>
            <td width="462" height="42" colspan="2">
              <div align="center">
                <strong>Time until extinguishment starts</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FF0000">
              <div align="center">30 - 120 min</div>
            </td>
            <td width="191" height="42" bgcolor="#FF0000">
              <div align="center">15-30 min</div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">3-5 min</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">
                <strong>0.5 - 1 min</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td width="462" height="72" colspan="2">
              <div align="center">
                <strong>Damage before extinguishment starts</strong>
              </div>
            </td>
            <td width="199" height="72" bgcolor="#FF0000">
              <div align="center">
                heat stress and deformation on the upper side of the tank wall
              </div>
            </td>
            <td width="191" height="72" bgcolor="#FF0000">
              <div align="center">
                heat stress and deformation on the upper side of the tank wall
              </div>
            </td>
            <td width="216" height="72" bgcolor="#FFC000">
              <div align="center">low scale</div>
            </td>
            <td width="212" height="72" bgcolor="#92D050">
              <div align="center">not expected</div>
            </td>
          </tr>
          <tr>
            <td width="462" height="42" colspan="2">
              <div align="center">
                <strong>Normal foam-solution intensity (L/min/m2)</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FFC000">
              <div align="center">5 - 10</div>
            </td>
            <td width="191" height="42" bgcolor="#FFC000">
              <div align="center">5 - 8</div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">5 - 12</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">15 - 50+</div>
            </td>
          </tr>
          <tr>
            <td width="462" height="42" colspan="2">
              <div align="center">
                <strong>Typical extinguishment time</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FF0000">
              <div align="center">2 - 48 hours</div>
            </td>
            <td width="191" height="42" bgcolor="#FF0000">
              <div align="center">1 - 24 hours</div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">30 min</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">2-3 min</div>
            </td>
          </tr>
          <tr>
            <td width="462" height="42" colspan="2">
              <div align="center">
                <strong>Overall damage</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FF0000">
              <div align="center">
                severe damage to the tank, usually needs to be demolished
              </div>
            </td>
            <td width="191" height="42" bgcolor="#FF0000">
              <div align="center">
                large-scale damage, high repair cost or demolition required
              </div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">low-scale damage</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">low-scale or no damage&nbsp;</div>
            </td>
          </tr>
          <tr>
            <td width="462" height="42" colspan="2">
              <div align="center">
                <strong>Extinguisment cost</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FF0000">
              <div align="center">very high</div>
            </td>
            <td width="191" height="42" bgcolor="#FF0000">
              <div align="center">high</div>
            </td>
            <td width="216" height="42" bgcolor="#92D050">
              <div align="center">low</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">low</div>
            </td>
          </tr>
          <tr>
            <td rowspan="4" bgcolor="#D6E3BC">
              <div align="center">
                <strong>OUTSIDE FACTORS</strong>
              </div>
            </td>
            <td width="245" height="42" bgcolor="#D6E3BC">
              <div align="center">
                <strong>Special staff requirements</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FF0000">
              <div align="center">very high</div>
            </td>
            <td width="191" height="42" bgcolor="#FFC000">
              <div align="center">high</div>
            </td>
            <td width="216" height="42" bgcolor="#92D050">
              <div align="center">not required</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">not required</div>
            </td>
          </tr>
          <tr>
            <td width="245" height="42" bgcolor="#D6E3BC">
              <div align="center">
                <strong>Roads, traffic</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FFC000">
              <div align="center">yes</div>
            </td>
            <td width="191" height="42" bgcolor="#FFC000">
              <div align="center">yes</div>
            </td>
            <td width="216" height="42" bgcolor="#92D050">
              <div align="center">no</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">no</div>
            </td>
          </tr>
          <tr>
            <td width="245" height="42" bgcolor="#D6E3BC">
              <div align="center">
                <strong>Fuel</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FFC000">
              <div align="center">yes</div>
            </td>
            <td width="191" height="42" bgcolor="#FFC000">
              <div align="center">yes</div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">yes</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">no</div>
            </td>
          </tr>
          <tr>
            <td width="245" height="42" bgcolor="#D6E3BC">
              <div align="center">
                <strong>Electricity</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#92D050">
              <div align="center">no</div>
            </td>
            <td width="191" height="42" bgcolor="#FFC000">
              <div align="center">yes</div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">yes</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">no</div>
            </td>
          </tr>
          <tr>
            <td rowspan="2" bgcolor="#95B3D7">
              <div align="center">
                <strong>OPERATIONAL RELIABILITY</strong>
              </div>
            </td>
            <td width="245" height="42" bgcolor="#95B3D7">
              <div align="center">
                <strong>Operational Reliability level</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#92D050">
              <div align="center">high</div>
            </td>
            <td width="191" height="42" bgcolor="#FF0000">
              <div align="center">low</div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">moderate</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">
                <strong>very high</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td width="245" height="210" bgcolor="#95B3D7">
              <div align="center">
                <strong>
                  Variables that affect operational reliability; potential
                  pitfalls
                </strong>
              </div>
            </td>
            <td width="199" height="210">
              <div align="center">
                Condition of fire vehicles and their equipment, traffic, fire
                brigade skill and training levels, access to water, availability
                of fire engines, availability of foam concentrate
              </div>
            </td>
            <td width="191" height="210">
              <div align="center">
                Condition of fire vehicles and their equipment, traffic, fire
                brigade skill and training levels, condition of water pumps,
                access to water, availability of fire engines, availability of
                foam concentrate, maintenance failure
              </div>
            </td>
            <td width="216" height="210">
              <div align="center">
                Electricity supply, condition of pumps and foam mixers, foam
                concentrate supply, maintenance failure
              </div>
            </td>
            <td width="212" height="210">
              <div align="center">Mechanical damage, maintenance failure</div>
            </td>
          </tr>
          <tr>
            <td rowspan="2" bgcolor="#FABF8F">
              <div align="center">
                <strong>EXTINGUISHMENT RELIABILITY</strong>
              </div>
            </td>
            <td width="245" height="42" bgcolor="#FABF8F">
              <div align="center">
                <strong>Extinguishment Reliability level</strong>
              </div>
            </td>
            <td width="199" height="42" bgcolor="#FF0000">
              <div align="center">low</div>
            </td>
            <td width="191" height="42" bgcolor="#FFC000">
              <div align="center">moderate</div>
            </td>
            <td width="216" height="42" bgcolor="#FFC000">
              <div align="center">moderate</div>
            </td>
            <td width="212" height="42" bgcolor="#92D050">
              <div align="center">
                <strong>very high</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td width="245" height="80" bgcolor="#FABF8F">
              <div align="center">
                <strong>
                  Variables that affect extinguishment reliability; potential
                  pitfalls
                </strong>
              </div>
            </td>
            <td width="199" height="80">
              <div align="center">
                foam, fuel or water supplies run out, updraft, wind or long
                extinguishment distance
              </div>
            </td>
            <td width="191" height="80">
              <div align="center">foam, fuel or water supplies run out</div>
            </td>
            <td width="216" height="80">
              <div align="center">
                expired or poor-quality foam concentrate, capacity limitations
              </div>
            </td>
            <td width="212" height="80">
              <div align="center">expired or poor-quality foam concentrate</div>
            </td>
          </tr>
          <tr>
            <td rowspan="3" bgcolor="#BFBFBF">
              <div align="center">
                <strong>FINANCIAL COMPARISON</strong>
              </div>
            </td>
            <td height="42" bgcolor="#BFBFBF">
              <div align="center">
                <strong>CAPEX</strong>
              </div>
            </td>
            <td height="42" bgcolor="#FFC000">
              <div align="center">moderate</div>
            </td>
            <td height="42" bgcolor="#FFC000">
              <div align="center">moderate</div>
            </td>
            <td height="42" bgcolor="#FF0000">
              <div align="center">high</div>
            </td>
            <td height="42" bgcolor="#FFC000">
              <div align="center">
                <strong>low/moderate</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td height="42" bgcolor="#BFBFBF">
              <div align="center">
                <strong>OPEX</strong>
              </div>
            </td>
            <td height="42" bgcolor="#FF0000">
              <div align="center">extremely high</div>
            </td>
            <td height="42" bgcolor="#FF0000">
              <div align="center">extremely high</div>
            </td>
            <td height="42" bgcolor="#FF0000">
              <div align="center">high</div>
            </td>
            <td height="42" bgcolor="#92D050">
              <div align="center">
                <strong>low</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td height="42" bgcolor="#BFBFBF">
              <div align="center">
                <strong>Maintenance requirement</strong>
              </div>
            </td>
            <td height="42" bgcolor="#FF0000">
              <div align="center">high</div>
            </td>
            <td height="42" bgcolor="#FFC000">
              <div align="center">moderate</div>
            </td>
            <td height="42" bgcolor="#FF0000">
              <div align="center">high</div>
            </td>
            <td height="42" bgcolor="#92D050">
              <div align="center">
                <strong>low</strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default comparison_table;
